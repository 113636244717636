"use client";
import { Toaster } from "react-hot-toast";

export default function PublicLayout(props: { children?: any }) {
  return (
    <main className="relative flex h-[var(--app-height)] flex-col items-center justify-center space-y-10 bg-gray-50 text-gray-800">
      <Toaster position="bottom-center" toastOptions={{ duration: 10000 }} />
      {props.children}
    </main>
  );
}
