const sidebarMenu = () =>
  [
    {
      name: "Admin",
      admin: true,
      menuItems: [
        {
          id: "admin-dashboard",
          name: "Dashboard",
          path: `/dashboard/admin/stats`,
        },
        {
          id: "admin-logs",
          name: "Logs",
          path: `/dashboard/admin/logs`,
        },
      ],
    },
    {
      name: "Management",
      admin: true,
      menuItems: [
        {
          id: "admin-site",
          name: "Site",
          path: `/dashboard/admin/site`,
        },
        {
          id: "admin-users",
          name: "Users",
          path: `/dashboard/admin/users`,
        },
        {
          id: "admin-subscriptions",
          name: "Subscriptions",
          path: `/dashboard/admin/subscriptions`,
        },
        {
          id: "admin-product",
          name: "Products",
          path: `/dashboard/admin/products`,
        },
        {
          id: "admin-resources",
          name: "Resources",
          path: `/dashboard/admin/resources`,
        },
        {
          id: "admin-ai-tools",
          name: "AI Tools",
          path: `/dashboard/admin/tools`,
        },
      ],
    },
    {
      name: "Developer",
      admin: false,
      menuItems: [
        {
          id: "playground",
          name: "Playground",
          path: `/workspace`,
        },
        {
          id: "api-key",
          name: "API key",
          path: `/dashboard/keys`,
        },
        {
          id: "resources",
          name: "Resources",
          path: `/dashboard/resources`,
        },
      ],
    },
    {
      name: "Billing",
      admin: false,
      menuItems: [
        {
          id: "billing",
          name: "Subscriptions",
          path: `/dashboard/subscriptions`,
        },
        {
          id: "invoices",
          name: "Invoices",
          path: `/dashboard/invoices`,
        },
      ],
    },
    {
      name: "Account",
      admin: false,
      menuItems: [
        {
          id: "notifications",
          name: "Notifications",
          path: `/dashboard/notifications`,
        },
        {
          id: "dashboard",
          name: "Usage",
          path: `/dashboard`,
        },
        {
          id: "settings",
          name: "Settings",
          path: `/dashboard/settings`,
        },
      ],
    },
  ] as const;

export const getLinkById = (
  id: ReturnType<typeof sidebarMenu>[number]["menuItems"][number]["id"]
) => {
  let link: string = "";
  a: for (const item of sidebarMenu()) {
    for (const menuItem of item.menuItems) {
      if (menuItem.id === id) {
        link = menuItem.path;
        break a;
      }
    }
  }
  return link as ReturnType<
    typeof sidebarMenu
  >[number]["menuItems"][number]["path"];
};

export default sidebarMenu;
