import {
    ExternalLink as ExternalLinkIcon,
    Pencil as PencilIcon,
    RotateCw as RotateCwIcon,
    PackageSearch as PackageSearchIcon,
    Import as ImportIcon,
    PlusCircle as PlusCircleIcon,
    ChevronDown as ChevronDownIcon,
    ChevronUp as ChevronUpIcon,
    Eraser as EraserIcon,
    Check as CheckIcon,
    Bookmark as BookmarkIcon,
    MessageSquare as MessageSquareIcon,
    ChevronRight as ChevronRightIcon,
    Copy as CopyIcon,
    Globe as GlobeIcon,
    Stars as StarsIcon,
    Wand2 as Wand2Icon,
    Minus as MinusIcon,
    Plus as PlusIcon,
    HelpCircle as HelpCircleIcon,
    X as XIcon,
    Brain as BrainIcon,
    Sprout as SproutIcon,
    Tag as TagIcon,
    ShoppingBag as ShoppingBagIcon,
    Circle as CircleIcon,
    Edit as EditIcon,
    Paintbrush as PaintbrushIcon,
    Trash2 as Trash2Icon,
    MoreVertical as MoreVerticalIcon,
    ArrowDown as ArrowDownIcon,
    Settings as SettingsIcon,
    Square as SquareIcon,
    Send as SendIcon,
    MoreHorizontal as MoreHorizontalIcon,
    Eye as EyeIcon,
    GraduationCap as GraduationCapIcon,
    User as UserIcon,
    Bot as BotIcon,
    XCircle as XCircleIcon,
    ChevronLeft as ChevronLeftIcon,
    RefreshCw as RefreshCwIcon,
    BookOpen as BookOpenIcon,
    Clipboard as ClipboardIcon,
    Download as DownloadIcon,
    FileInput as FileInputIcon,
    Key as KeyIcon,
    SlidersHorizontal as SlidersHorizontalIcon,
    Search as SearchIcon,
    FolderPlus as FolderPlusIcon,
    MessageCircleOff as MessageCircleOffIcon,
    Scan as ScanIcon,
    CloudOff as CloudOffIcon,
    Sun as SunIcon,
    Moon as MoonIcon,
    Building2 as Building2Icon,
    Home as HomeIcon,
    LogOut as LogOutIcon,
    Wrench as WrenchIcon,
    Bell as BellIcon,
    FileUp as FileUpIcon,
    Crosshair as CrosshairIcon,
    Printer as PrinterIcon,
    AlignLeft as AlignLeftIcon,
    AlignRight as AlignRightIcon,
    EyeOff as EyeOffIcon,
    Mail as MailIcon,
    AlertTriangle as AlertTriangleIcon,
    MessageCircle as MessageCircleIcon,
    Upload as UploadIcon,
    Loader2 as Loader2Icon,
    ThumbsDown as ThumbsDownIcon,
    ThumbsUp as ThumbsUpIcon,
    Filter as FilterIcon,
    Menu as MenuIcon,
    Sparkles as SparklesIcon,
    Link2 as Link2Icon,
    Link2Off as Link2OffIcon,
    GitBranch as GitBranchIcon,
    Pin as PinIcon,
    ArrowLeft as ArrowLeftIcon,
    Router as RouterIcon,
    CircleDot as CircleDotIcon,
    Zap as ZapIcon,
    List as ListIcon,
    ArrowRight as ArrowRightIcon,
    Dices as DicesIcon,
    Pause as PauseIcon,
    Target as TargetIcon,
    FileText as FileTextIcon,
    Layers as LayersIcon,
    Route as RouteIcon,
    ChevronsUpDown as ChevronsUpDownIcon,
    FileCode as FileCodeIcon,
    Heart as HeartIcon,
    Flag as FlagIcon,
    Book as BookIcon,
    MessageCircleQuestion as MessageCircleQuestionIcon,
    Inspect as InspectIcon,
    Maximize as MaximizeIcon,
    Component as ComponentIcon,
    Lock as LockIcon,
    Share as ShareIcon,
    ArrowUpLeftIcon
} from "lucide-react";

export const IconList = ListIcon;
export const IconPencil = PencilIcon;
export const IconReload = RotateCwIcon;
export const IconExternalLink = ExternalLinkIcon;
export const IconLink = Link2Icon;
export const IconPackageExport = PackageSearchIcon;
export const IconTableImport = ImportIcon;
export const IconPlusCircle = PlusCircleIcon;
export const IconCaretDown = ChevronDownIcon;
export const IconCaretUp = ChevronUpIcon;
export const IconClearAll = EraserIcon;
export const IconCheck = CheckIcon;
export const IconBookmark = BookmarkIcon;
export const IconMessage = MessageSquareIcon;
export const IconChevronRight = ChevronRightIcon;
export const IconCopy = CopyIcon;
export const IconWorld = GlobeIcon;
export const IconStars = StarsIcon;
export const IconWand = Wand2Icon;
export const IconMinus = MinusIcon;
export const IconPlus = PlusIcon;
export const IconQuestionMark = HelpCircleIcon;
export const IconX = XIcon;
export const IconBrain = BrainIcon;
export const IconPlant = SproutIcon;
export const IconTag = TagIcon;
export const IconPaperBag = ShoppingBagIcon;
export const IconCircle = CircleIcon;
export const IconEdit = EditIcon;
export const IconPaint = PaintbrushIcon;
export const IconTrash = Trash2Icon;
export const IconDotsVertical = MoreVerticalIcon;
export const IconArrowDown = ArrowDownIcon;
export const IconSettings2 = SettingsIcon;
export const IconPlayerStop = SquareIcon;
export const IconSend = SendIcon;
export const IconDots = MoreHorizontalIcon;
export const IconEye = EyeIcon;
export const IconSchool = GraduationCapIcon;
export const IconUser = UserIcon;
export const IconRobot = BotIcon;
export const IconCircleX = XCircleIcon;
export const IconChevronLeft = ChevronLeftIcon;
export const IconRefresh = RefreshCwIcon;
export const IconBooks = BookOpenIcon;
export const IconCaretRight = ChevronRightIcon;
export const IconClipboard = ClipboardIcon;
export const IconDownload = DownloadIcon;
export const IconFileImport = FileInputIcon;
export const IconKey = KeyIcon;
export const IconAdjustments = SlidersHorizontalIcon;
export const IconSearch = SearchIcon;
export const IconFolderPlus = FolderPlusIcon;
export const IconMessagesOff = MessageCircleOffIcon;
export const IconScan = ScanIcon;
export const IconMistOff = CloudOffIcon;
export const IconSettings = SettingsIcon;
export const IconSun = SunIcon;
export const IconMoon = MoonIcon;
export const IconAdjustmentsPlus = SlidersHorizontalIcon;
export const IconBuilding = Building2Icon;
export const IconHome = HomeIcon;
export const IconDoorExit = LogOutIcon;
export const IconTool = WrenchIcon;
export const IconBell = BellIcon;
export const IconFileUpload = FileUpIcon;
export const IconFocusCentered = CrosshairIcon;
export const IconPrinter = PrinterIcon;
export const IconTextDirectionLtr = AlignLeftIcon;
export const IconTextDirectionRtl = AlignRightIcon;
export const IconEyeClosed = EyeOffIcon;
export const IconMail = MailIcon;
export const IconAlertTriangle = AlertTriangleIcon;
export const IconMessage2 = MessageCircleIcon;
export const IconUpload = UploadIcon;
export const IconLoader2 = Loader2Icon;
export const IconThumbsDown = ThumbsDownIcon;
export const IconThumbsUp = ThumbsUpIcon;
export const IconMessageSquare = MessageSquareIcon;
export const IconChevronLeftSquare = ChevronLeftIcon;
export const IconChevronRightSquare = ChevronRightIcon;
export const IconChevronDown = ChevronDownIcon;
export const IconFilter = FilterIcon;
export const IconSplit = ChevronsUpDownIcon;
export const IconMenu = MenuIcon;
export const IconSparkle = SparklesIcon;
export const IconLinkIcon = Link2Icon;
export const IconLinkOff = Link2OffIcon;
export const IconListTree = GitBranchIcon;
export const IconPin = PinIcon;
export const IconVerified = CheckIcon;
export const IconListTodo = ListIcon;
export const IconChevronUp = ChevronUpIcon;
export const IconArrowLeft = ArrowLeftIcon;
export const IconRefreshCw = RefreshCwIcon;
export const IconArrowRight = ArrowRightIcon;
export const IconDices = DicesIcon;
export const IconPause = PauseIcon;
export const IconTarget = TargetIcon;
export const IconNotebookText = FileTextIcon;
export const IconHelpCircle = HelpCircleIcon;
export const IconLayers2 = LayersIcon;
export const IconRoute = RouteIcon;
export const IconListCollapse = ChevronsUpDownIcon;
export const IconFileCode2 = FileCodeIcon;
export const IconHeart = HeartIcon;
export const IconFlag = FlagIcon;
export const IconBook = BookIcon;
export const IconMessageCircleQuestion = MessageCircleQuestionIcon;
export const IconZap = ZapIcon;
export const IconInspect = InspectIcon;
export const IconSparkles = SparklesIcon;
export const IconRouter = RouterIcon;
export const IconCircleDot = CircleDotIcon;
export const IconExpand = MaximizeIcon;
export const IconComponent = ComponentIcon;
export const IconLockClosed = LockIcon;
export const IconDocumentDuplicate = CopyIcon;
export const IconBars3 = MenuIcon;
export const IconShare = ShareIcon;
export const IconArrowUpLeft = ArrowUpLeftIcon;