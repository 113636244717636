"use client";
// import bg from "../../../../../public/images/bg.png";
// import bgLight from "../../../../../public/images/bg.png";
import Link from "next/link";
import useTheme from "@/lib/theme/usetheme";

export default function Hero({ siteConfig }: {
  siteConfig: {
    displayName?: string | null,
    description?: string | null
  }
}) {
  const theme = useTheme();
  return (
    <div
      className="hero min-h-screen"
      style={{
        backgroundImage:
          theme === "dark" ? `url(/images/bg.png)` : `url(/images/bg.png)`,
      }}
    >
      <div className="hero-overlay h-full w-full backdrop-blur-sm"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold">
            <span className="block">{siteConfig.displayName}</span>
            <span className="block text-primary">Playground</span>
          </h1>
          <p className="mb-5">
            {siteConfig.description}
          </p>
          <Link className="btn btn-primary" href="/auth/login">
            Quick Start
          </Link>
        </div>
      </div>
    </div>
  );
}
