import { useLocalStorage } from "usehooks-ts";

export default function useTheme() {
    const { theme } = useThemeStorage();
    return theme;
}

export function useThemeStorage() {
    const [theme, setTheme] = useLocalStorage(".theme", typeof window !== "undefined" ? (window.matchMedia('(prefers-color-scheme: light)').matches ? "light" : "dark") : "dark");
    return { theme, setTheme, toggleTheme: () => setTheme(theme === "dark" ? "light" : "dark") };
}