"use client";

import DarkThemeButton from "@/components/Button/darktheme";
import sidebarMenu from "@/config/menu/sidebar-static";
import type { User } from "@prisma/client";
import Link from "next/link";

export default function LandingHeaderControls(props: { user?: User }) {
  return (
    <div className="flex gap-3">
      <DarkThemeButton />
      <Link
        href={props.user ? sidebarMenu()[1].menuItems[0].path : "/login"}
        className="btn-primary btn"
      >
        {props.user ? "Dashboard" : "Login"}
      </Link>
    </div>
  );
}
